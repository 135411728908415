@tailwind base;
@tailwind components;
@tailwind utilities;
body{
    color: #111827;
}
.navigationmenuitem.active{
    color: #01b5f1;
}

select{
    -webkit-appearance: none;
}

.bgmoveanimation {
animation: movebgpositionkeyframe 60s linear infinite;
}

.single_content p {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

.single_content p:last-child{
    margin-bottom: 0;
}

.single_content .wp-block-image{
    margin-bottom: 1.3rem;
}

.single_content a{
    color: #4337c9;
    transition: all 0.2s;
}

.single_content a:hover{
    text-decoration: underline;
}

.single_content h1 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 0.8rem;
    margin-top: 2.5rem;
}

.single_content h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.8rem;
    margin-top: 2.5rem;
}

.single_content h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
}

.single_content  .wp-block-embed__wrapper iframe {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.75rem;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.single_content  .wp-block-embed__wrapper {
    padding-top: 56.25%;
    margin-bottom: 1.7rem;
}

.single_content img{
    max-width: 100%;
    height: auto;
}

.single_content figure.wp-block-image {
    position: relative;
}

.single_content figure.wp-block-image figcaption {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: #fff;
    margin: 0;
    padding: 5px 15px;
    display: inline-block;
    background: rgb(79 70 229 / 80%);
    border-radius: 0.5rem;
    font-size: 0.7rem;    
}
.single_content pre.wp-block-code {
    border-radius: 0.75rem;
    margin-bottom: 1.5rem;
    padding: 0;
    overflow: hidden;
    border: 2px solid #4f45e4;
    background: #c7d2fe85;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.single_content pre.wp-block-code::before {content: "কোড স্নিপেটঃ";background: #4f45e4;display: block;color: #fff;font-weight: bold;font-size: 0.9rem;padding: 0.5rem 1.5rem;}

.single_content pre.wp-block-code code {
    padding: 1.5rem;
    color: #4f46e5;
    font-family: monospace;
}
.single_content li {
    margin: 0.4rem 0;
}

.single_content ol, .single_content ul {
    padding-left: 1.4rem;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

.single_content ul {
    list-style: disc;
}

.participantscontent a{
    text-transform: uppercase;
    border: 1px solid #fff;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 10px;
    transition: all 0.3s;
}
.participantscontent a:hover{
    background: #fff;
    color: #333;
}
  
@keyframes movebgpositionkeyframe {
    0% {
        background-position: 400px 1000px;
    }

    50% {
        background-position: 1000px 400px;
    }

    100% {
        background-position: 400px 1000px;
    }
}